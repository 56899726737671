.login_portal {
  height: 100%;
  overflow: auto;
}
.login_portal header {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  line-height: 48px;
  height: 48px;
}
.login_portal header .logo {
  padding: 0 10px;
  cursor: pointer;
}
.login_portal header .back {
  padding: 0 10px;
  width: 18px;
  height: 18px;
  vertical-align: text-top;
}
.login_portal .body {
  padding: 0 50px;
  color: #666;
  font-size: 14px;
  max-width: 1200px;
  margin: 0 auto;
}
.login_portal .body h2 {
  text-align: center;
  padding: 50px;
}
.login_portal .body h3 {
  border-bottom: #eee 1px solid;
  line-height: 48px;
}
.login_portal .body h4 {
  line-height: 24px;
  margin: 0;
}

.login_portal .footer {
  text-align: center;
  color: #4a4a4a;
  margin-top: 50px;
}
