.login {
  background: #fff;
  height: 100%;
  overflow: hidden;
  background: linear-gradient(238deg, #8acf6a 0%, rgb(34, 103, 172) 100%);
}

.login_footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  color: #333;
  font-size: 12px;
  padding: 10px;
}

.login_footer a {
  text-decoration: none;
  color: #333;
}

.login_logo {
  color: #333;
  display: block;
  font-size: 20px;
  text-decoration-line: none;
  padding: 20px;
  margin: 0 auto; /* 居中显示 */
  text-align: center; /* 内容居中显示 */
}

.login_logo img {
  width: 100px;
  height: 100px;
}

.login .login_box {
  background: #fff;
  width: 380px;
  height: 52%;
  min-height: 350px;
  margin: 0 auto;
  border-radius: 4px;
  box-shadow: #ffffff 0 2px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login_info {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
}

@media screen and (max-width: 768px) {
  .login {
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
  }

  .login .login_box {
    background: transparent;
    color: #fff;
  }

  .login_info {
    height: 100%;
    width: 100%;
    color: #333;
  }
}
