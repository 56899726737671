.login_box {
  background: #fff;
  width: 380px;
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: #ffffff;
  background-clip: padding-box;
  border: 0;
  border-radius: 8px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);

  position: relative;
  user-select: none;
}

.login_plus_btn {
  display: inline-block;
  padding: 6px 10px;
  margin: 0 10px;
  border-radius: 5px;
  border: #9b9b9b solid 1px;
  cursor: pointer;
}

.login_box_switch {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 64px;
  height: 64px;
  overflow: hidden;
  border-radius: 5px;
}

.login_box_btn {
  background-size: 36px;
  width: 64px;
  height: 64px;
  transform: skewY(45deg);
  transform-origin: top right;
  cursor: pointer;
  border-top-right-radius: 10px;
  overflow: hidden;
  position: relative;
  background: #eee;
  border-radius: 5px;
}

.login_box_btn .btn {
  position: absolute;
  left: 13px;
  top: 42px;
  width: 38px;
  height: 38px;
  transform: skewY(315deg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.login_box_btn .weixin {
  background-image: url(./id-card.png);
}
.login_box_btn .pwd {
  background-image: url(./qr-code.png);
}

.login_box_switch2 {
  border-top: #eee 1px dashed;
  width: 80%;
  margin-top: 10px;
  text-align: center;
}

.login_box_switch2 button {
  width: 28px;
  height: 28px;
  background-size: cover;
  margin: 14px;
  cursor: pointer;
  border: none;
}
.login_box_switch2 .weixin {
  background-image: url(./id-card.png);
}
.login_box_switch2 .pwd {
  background-image: url(./qr-code.png);
}
