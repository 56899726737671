.login_dialog {
  width: 380px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000;
  position: absolute;
  top: 0;
  left: calc(50% - 190px);
}

.login_dialog_mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  z-index: 1000;
}

.login_dialog_close {
  display: none;
}

@media screen and (max-width: 768px) {
  .login_dialog {
    background: #fff;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    align-items: start;
  }

  .login_dialog .login_box{
    width: 100%;
  }

  .login_dialog_close {
    display: block;
    background: url(close.png) no-repeat center center;
    background-size: 20px auto;
    width: 30px;
    height: 30px;
    position: absolute;
    left: 15px;
    top: 15px;
    cursor: pointer;
    z-index: 2001;
  }

  .login_dialog_mask {
    display: none;
  }

  .login_box {
    box-shadow: none !important;
  }

  .login_pwd {
    height: 260px !important ;
  }
}
