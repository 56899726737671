html,
body,
#root {
  margin: 0;
  padding: 0;
  font-family: Helvetica Neue, Helvetica, Hiragino Sans GB, Microsoft YaHei,
    Arial, sans-serif;
  height: 100%;
  overflow: hidden;
  user-select: none;
}
