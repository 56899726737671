.login_pwd {
  width: 100%;
  height: 400px;
  max-width: 480px;
}
.login_pwd p {
  padding: 0 40px;
  text-align: center;
  font-size: 20px;
  height: 32px;
  line-height: 32px;
  margin: 0;
  margin-bottom: 30px;
}

.login_pwd .box {
  padding: 0 40px;
}

.login_pwd input[type="text"],
.login_pwd input[type="password"] {
  height: 40px;
  border-radius: 6px;
  font-weight: 400;
  background: #f7f7f9;
  color: #19191a;
  border: 1px solid transparent;
  font-size: 14px;
  line-height: 20px;
  width: 100%;
  margin-bottom: 10px;
  border: #fff 1px solid;
  padding: 0 10px;
  box-sizing: border-box;
  outline: none;
}

.login_pwd input[type="text"]:hover,
.login_pwd input[type="password"]:hover {
  background: #a18484; /* 这是一个比#00b96b稍深的绿色 */
}

.login_pwd label {
  font-size: 14px;
  display: block;
  padding: 5px 0 10px 0;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
  75% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
  }
}

.shake {
  display: inline-block;
  animation: shake 0.35s;
}

.login_pwd a {
  text-decoration: none;
  color: #00b96b;
}

.login_pwd input:focus {
  border: #00b96b 1px solid;
}

.login_pwd button {
  width: 100%;
  height: 40px;
  padding: 14px 0;
  font-size: 14px;
  line-height: 11px;
  text-align: center;
  box-sizing: border-box;
  border-radius: 6px;
  cursor: pointer;
  user-select: none;
  font-weight: 500;
  transition: all 0.15s ease-in-out;
  color: #fff;
  background: #00b96b;
  border: none;
  outline: none;
}

.login_pwd button:hover {
  background: #009657; /* 这是一个比#00b96b稍深的绿色 */
}
